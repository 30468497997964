import './App.css';
import { BrowserRouter as Router,Route, Routes, } from 'react-router-dom';
import Home from './component/Home/Home';
import User from './component/User/User';
import Comment from './component/Comment/Comment';

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
      <Route exact path="/" element= {<Home/>} />
      <Route path = "/user/:id" element = {<User/>} />
      <Route path = "/user/:id/:comments" element = {<Comment/>} />
      </Routes>
           
        </Router>
    
    </div>
  );
}

export default App;
